import React, { useEffect, useState, useRef } from 'react'
import 'typeface-inter'
import { ThemeProvider, Global, css } from '@emotion/react'
import Header from '../components/Header'
import { theme } from '../styles/theme'
import '../styles/styles.css'
import { useSiteInfoQuery } from '../queries/useSiteInfoQuery'


const Layout = ( { children, slogan } ) => {
    const { colorText } = useSiteInfoQuery()

    return (
        <div
          className='layout'
        >
            <Global
              styles={ css`
              * {
               color: ${ colorText };
               font-family: Nunito, Helvetica, Arial, sans-serif;
              }
              a {
                  text-decoration: none;
              }
              h1, p, a { color: ${ colorText };
                }
              .layout {
                ${ theme.breakpoints.mobile } {
                    width: 95%;
                    margin: 1rem auto;
                }
              }
              .content {
            height: 100%;
            margin-top: 4rem;
            letter-spacing: 1px;
            ${ theme.breakpoints.mobile } {
                margin-top: 2rem;
              }
            }
        ` }
            />
            <ThemeProvider theme={ theme }>
                <Header
                slogan={ slogan }
                />
                <div
                  className='content'

                >
                    {children}
                </div>
            </ThemeProvider>
        </div>
    )
}

export default Layout
