import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react'
import useDocumentScrollThrottled from '../customHooks/useDocumentScrollThrottled'
import { useSiteInfoQuery } from '../queries/useSiteInfoQuery'
import { useLocation } from '@reach/router';

const Header = ({ slogan }) => {
    const theme = useTheme()

    const { colorText, colorHighlight } = useSiteInfoQuery()

    const { site } = useStaticQuery( graphql`
    query {
      site {
        data: siteMetadata {
          menu {
            name
            to
          }
        }
      }
    }
  ` )

    const stylez = css`
        margin-bottom: 4rem;
        padding: 1rem 0;
        margin: 0 auto;
        background-color: white;
        z-index: 9;
        transform: translateY(0);
        transition: transform 0.3s ease-in-out;
        ${ theme.breakpoints.mobile } {
            padding: 1rem 0;
            /* margin-bottom: 1rem; */
        }
        ${ theme.breakpoints.tablet } {
            padding: 1rem 0;
            margin-bottom: 2rem;

        }


      .navi {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        ${ theme.breakpoints.mobile } {
          position: relative;
            /* justify-content: center; */
            a {
                width: 100%;
                /* text-align: center; */
              }
            }

        .menu_desktop {
          display: flex;
          justify-content: center;
          align-items: center;
          ${ theme.breakpoints.mobile } {
              position: absolute;
              top: -1rem;
              right: 0;
            }
          .menu_element {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .home_link {
            color: ${ colorHighlight };
            text-decoration: underline;
            font-weight: 600;

          }
          span {
            /* color: ${ colorHighlight }; */
            margin: 0 1rem;
            font-size: 1.5rem;
            font-weight: 100;
          }
        }
          .menu_desktop_link {
            font-size: 1.5rem;
            font-weight: 100;
            letter-spacing: 2px;
            user-select: none;
            ${ theme.breakpoints.mobile } {
              font-size: 1.5rem;
            }
            ${ theme.breakpoints.tablet } {
            font-size: 1.5rem;
            }
            ${ theme.breakpoints.largeDesktop } {
            font-size: ${ theme.fontSizes.large };
            }
            :hover {
              /* font-weight: 600; */
              color: ${ colorHighlight };
              text-decoration: underline;
            }
          }
          .menu_desktop_link_active {
            font-weight: 600;
            color: ${ colorHighlight };
            text-decoration: underline;

          }
        }
      }

      .owner_name {
        font-size: 2rem;
        font-weight: 200;
        letter-spacing: 4px;
            ${ theme.breakpoints.mobile } {
              font-size: 1.6rem;
              letter-spacing: 2px;
            /* margin-bottom: 1rem; */
            width: 100%;
            margin-top: 4rem;
            }
            ${ theme.breakpoints.tablet } {
            /* font-size: 1.5rem; */
            }
            ${ theme.breakpoints.largeDesktop } {
            font-size: 2.5rem;
            }
      }
      .slogan {
        font-size: 2rem;
        font-weight: 600;
        letter-spacing: 4px;
        color: ${ colorHighlight };
            ${ theme.breakpoints.mobile } {
              letter-spacing: 2px;
            font-size: 1.5rem;
            margin-bottom: 1rem;
            width: 100%;
            }
            ${ theme.breakpoints.tablet } {
            /* font-size: 1.5rem; */
            }
            ${ theme.breakpoints.largeDesktop } {
            font-size: 2.5rem;
            }
      }
  `
  const location = useLocation().pathname

    return (
        <header
          className='header'
          css={ stylez }
        >

            <div className='navi'>
                <Link
                  to='/'
                >
                    {/* <img alt='Logo' className='' src='logo.svg' /> */}
                    <h1 className='owner_name'>
                      Jocelyn Polen
                    </h1>
                    <div className="slogan">
                      { slogan }
                    </div>
                </Link>

                <div className='menu_desktop'>
                    {site.data.menu.map( ( link, key ) => (
                      <div className='menu_element' key={ key }>
                          <Link
                            className={`menu_desktop_link ${ ( link.name === 'EN' ) && ( location === '/english' ) && 'home_link' }`}
                            activeClassName='menu_desktop_link_active'
                            to={ link.to }
                            >
                              {link.name}
                          </Link>
                          {
                            link.name !== 'ES' && <span>
                            /
                          </span>
                          }

                        </div>
                    ) )}
                </div>

            </div>

        </header>
    )
}

export default Header
