export const theme = {
    breakpoints: {
        mobile: '@media (max-width: 800px)',
        tablet: '@media only screen and (min-width: 801px) and (max-width: 1024px) ',
        largeDesktop: '@media (min-width: 1850px)',
    },
    fonts: {
        primary: 'Nunito, Helvetica, Arial, sans-serif',
    },
    fontSizes: {
        small: '1rem',
        medium: '1.2rem',
        large: '2rem',
    },
}
